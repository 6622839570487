/* eslint-disable no-self-assign */
import BaseService from '../base/BaseService';

const _push = (obj) => {
  if (window.dataLayer && obj) {
    window.dataLayer.push(obj);
    console.debug('Datalayer: ', window.dataLayer, 'lastPush: ', window.dataLayer[window.dataLayer.length - 1]);
  } else {
    console.warn('Datalayer does not exist!');
  }
};

const processImpressions = (impressionLists, rifinements) => {
  if (!impressionLists || !impressionLists.length) return false;
  impressionLists.forEach(function(list) {
    pushImpression(list, rifinements);
  });
};

const processImpressionsGA4 = (GA4ImpressionLists, rifinements) => {
  if (!GA4ImpressionLists || !GA4ImpressionLists.length) return false;
  GA4ImpressionLists.forEach(function(list) {
    pushGA4Impression(list, rifinements);
  });
};

const processClickGA4 = (GA4click) => {
  if (!GA4click || !GA4click.length) return false;
  pushGA4Click(GA4click);
};

const pushImpression = (cards, rifinements) => {
  rifinements = rifinements || {};
  var dataLayerObj = {
    event: rifinements.event || 'productImpressions',
    ecommerce: {
      currencyCode: rifinements.currencyCode || $('.page').data('currencyCode') || '',
      impressions: cards
    }
  };
  _push(dataLayerObj);
};

const pushGA4Impression = (cards, refinements) => {
  var dataLayerObj = {
    event: 'view_item_list',
    ecommerce: {
      currency: (refinements && refinements.currencyCode) || $('.page').data('currencyCode') || '',
      items: cards
    }
  };
  _push(dataLayerObj);
};

const pushGA4Click = (cards) => {
  var dataLayerObj = {
    event: 'select_item',
    ecommerce: {
      currency: $('.page').data('currencyCode') || '',
      items: cards
    }
  };
  if(cards.eventCallback){
    dataLayerObj.eventCallback = cards.eventCallback;
    dataLayerObj.eventTimeout = 300;
  }
  _push(dataLayerObj);
};

const dtlCardData = (cardData, currentList, position) => {
  if (!cardData) return null;
  var dtl = cardData.productcard || {};
  dtl.brand = dtl.brand;
  dtl.category = dtl.category;
  dtl.dimension10 = dtl.dimension10;
  dtl.dimension11 = dtl.dimension11;
  dtl.id = dtl.id;
  dtl.idcolor = dtl.idcolor;
  dtl.list = currentList || '';
  dtl.metric1 = dtl.metric1;
  dtl.name = dtl.name;
  dtl.pattern = dtl.pattern;
  dtl.position = position || 1;
  dtl.price = dtl.price;
  dtl.season = dtl.season;
  dtl.variant = dtl.variant;
  return dtl;
};

export default class DataLayerService extends BaseService {
  get Messages() {
    return {
      [this.CUSTOM_MESSAGES.DATALAYER_EVENTS.PUSH]: this.push,
      [this.CUSTOM_MESSAGES.DATALAYER_EVENTS.UPDATE_OPTION]: this.updateCheckoutOption,
      [this.CUSTOM_MESSAGES.DATALAYER_EVENTS.CHECK_NEW_IMPR]: this.checkForNewImpressions,
      [this.CUSTOM_MESSAGES.DATALAYER_EVENTS.CHECK_NEW_IMPR_GA4]: this.checkForNewImpressionsGA4,
      [this.CUSTOM_MESSAGES.DATALAYER_EVENTS.CHECK_NEW_CLICK_GA4]: this.checkForNewClickGA4
    };
  }

  get CHECKOUT_STEPS() {
    return ['cart', 'login', 'shipping', 'billing', 'placeOrder'];
  }

  constructor(element) {
    super(element);
  }

  push(dataLayer) {
    _push(dataLayer);
  }

  updateCheckoutOption({ stage, option }) {
    if (!stage || !option) {
      return;
    }

    const dataLayerObj = {
      event: 'checkoutOption',
      ecommerce: {
        checkout_option: {
          actionField: { step: this.CHECKOUT_STEPS.indexOf(stage) + 1, option: option.toLowerCase() }
        }
      }
    };
    _push(dataLayerObj);
  }
  checkForNewImpressions({ $region, force, dlRifinements }) {
    var impressionLists = [];
    var cardList;
    var position;
    var positionFirstImpl;
    var dlRifinements = dlRifinements || {};
    var force = force || false;
    var $selector = $region ? ($region.filter('[data-pid]').length && $region) || $region.find('[data-pid]') : $('[data-pid]');
    var _list = [];
    var imprDl = window.dataLayer.filter(function(ev) {
      return ev.event === 'productImpressions';
    });
    // faccio questo check perchè la prima parte delle impression viene caricata da processor
    if (imprDl.length > 0 && Object.keys(window.dataLayer.cardImpressions).length === 0) {
      for(var imprDlSingle of imprDl){
        var firstImpr = imprDlSingle.ecommerce.impressions;
        if (firstImpr.length > 0) {
          firstImpr.forEach(function(impr) {
            window.dataLayer.cardImpressions[impr.id + '_' + impr.idcolor.toUpperCase()] = impr;
          });
        }
      }
      positionFirstImpl = Object.keys(window.dataLayer.cardImpressions).length;
    }
    $selector.each(function(i, el) {
      var $el = $(el);
      var cardData = $el.data();
      var impr = window.dataLayer.cardImpressions[cardData.pid];

      var $parent = $el.parents('[data-listcontext]');

      // cerco di recuperare l'utlima positione un cache per questo elemento
      var cachePosition = force ? 0 : positionFirstImpl || $parent.data('listposition') || 0;

      var currentList = (!impr || force ? $parent.data('listcontext') : impr.list) || '';

      // verifico se processare o solo pre-processare l'impression
      var processing = force || (!cardData.delayimpression && !impr);
      var preprocessing = processing || !impr;
      if (preprocessing) {
        if (currentList !== cardList) {
          _list = [];
          if (processing) {
            impressionLists.push(_list);
          }
          position = cachePosition + 1;
        } else {
          position++;
        }
        positionFirstImpl = null;
        cardList = currentList;
        $parent.data('listposition', position);
        var dtl = dtlCardData(cardData, currentList, position);
        if (!dtl) return true;
        window.dataLayer.cardImpressions[cardData.pid] = dtl;

        // processo solo se richiesto
        if (processing) _list.push(dtl);
      }
    });
    let finalImpressionLists = [];
    for(let impressions of impressionLists) {

      let pushSize = 10;
      for (let i = 0; i < Math.ceil(impressions.length / pushSize); i++) {
          let impressionChunk = impressions.slice(i * pushSize, (i + 1) * pushSize);
          finalImpressionLists.push(impressionChunk);
      }
    }
    if (finalImpressionLists.length) processImpressions(finalImpressionLists, dlRifinements.impression);
  }

  checkForNewImpressionsGA4(ga4Impressions) {
    for (let i = 0; i < ga4Impressions.length; i++) {
      ga4Impressions[i].quantity = 1;
    }    
    let finalImpressionLists = [];
    
    let pushSize = 10;
    for (let i = 0; i < Math.ceil(ga4Impressions.length / pushSize); i++) {
        let impressionChunk = ga4Impressions.slice(i * pushSize, (i + 1) * pushSize);
        finalImpressionLists.push(impressionChunk);
    }
    
    processImpressionsGA4(finalImpressionLists);
  }

  checkForNewClickGA4(ga4Click) {
    processClickGA4(ga4Click);
  }
}
