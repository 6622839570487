export default async () => {
  let pageDiv = document.querySelector('.page');
  if (!pageDiv) {
    return;
  }
  let getWishlistUrl = pageDiv.dataset.getWishlistUrl;
  let wishlist;
  try {
    wishlist = await fetch(getWishlistUrl).then((r) => r.json());
  } catch (e) {
    console.error(e);
    return;
  }
  let pidsInWishlist = wishlist?.list?.items?.map((item) => item.id);
  if (!pidsInWishlist || !pidsInWishlist.length) {
    return;
  }
  let addToWishlistButtons = [...document.querySelectorAll('.add-to-wish-list'), ...document.querySelectorAll('.product-tile-wishlist-cta')];
  addToWishlistButtons.forEach((button) => {
    let pid = button.dataset.optionPid;

    if (pidsInWishlist.includes(pid)) {
      button.classList.add('is-in-wishlist');
      let heart = button.querySelector('.icon-wishlist');
      if (!heart) {
        return;
      }
      heart.classList.remove('icon-wishlist');
      heart.classList.add('icon-wishlist_filled');
      let arialabelactive = button.dataset.optionArialabelactive;
      if(arialabelactive){
        heart.setAttribute('aria-label', arialabelactive);
      }
    }
  });
};
