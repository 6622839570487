const trackingUtils = {
  startTracking: () => {
    let pageDiv = document.querySelector('.page');
    if (!pageDiv) {
      return;
    }
    let setTrackingUrl = pageDiv.dataset.setTrackingUrl;

    const listOfCategories = window.listOfCategories;

    window.OneTrust &&
      window.OneTrust.OnConsentChanged(function(e) {
        let consented = true;
        if(listOfCategories && listOfCategories.length){
          for (const cat of listOfCategories){
            if(!e.detail.includes(cat)){
              consented = false;
            }
          }
        } else {
          consented = e.detail && e.detail.length && e.detail.length > 1;
        }
        fetch(`${setTrackingUrl}?consent=${consented}`).catch((e) => {
          console.error(e);
        });
        
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        document.cookie = `c_track=${consented}; expires=${expirationDate.toUTCString()}; path=/`;
      });
  }
};

export default trackingUtils;
