import 'vex-js/dist/css/vex.css';
import VexDialog from 'vex-dialog';
import Vex from 'vex-js';
import Bean from 'bean';

import a11yUtils from '../utilities/A11yUtils';
import BaseService from '../base/BaseService';
export default class ModalService extends BaseService {
  get Messages() {
    return {
      [this.CUSTOM_MESSAGES.MODAL_EVENTS.show]: this.showModal,
      [this.CUSTOM_MESSAGES.MODAL_EVENTS.close]: this.closeModals
    };
  }

  get DEFAULT_OPTS() {
    return {
      content: '',
      unsafeContent: '',
      showCloseButton: true,
      escapeButtonCloses: true,
      overlayClosesOnClick: true,
      appendLocation: 'body',
      className: 'vex-theme-default',
      overlayClassName: '',
      contentClassName: '',
      closeClassName: '',
      closeAllOnPopState: true
    };
  }

  constructor(element) {
    super(element);
    this.Vex = Vex;
    this.Vex.registerPlugin(VexDialog);
  }

  handleResolutionChange() {
    const startWidth = window.innerWidth;
    Bean.on(window, 'resize.modal', (e) => {
      if (window.innerWidth !== startWidth) {
        const instances = this.Vex.getAll();
        if (!this.objectIsEmpty(instances)) {
          this.closeModals();
        }
      }
    });
  }

  async focus({ contentEl }) {
    const focusable = this.getFirstFocusable(contentEl);
    focusable.focus();
  }

  showModal(opts = {}) {
    const ctx = this;
    if (opts.selector) {
      if (!document.querySelector(opts.selector)) {
        return console.warn('No element found with selector => ', opts.selector);
      }
      opts.unsafeContent = document.querySelector(opts.selector).innerHTML;
    }
    return this.Vex.open({
      ...this.DEFAULT_OPTS,
      ...{
        afterOpen() {
          const vexInstance = this;
          ctx.EMIT(ctx.CUSTOM_MESSAGES.MODAL_EVENTS.afterOpen, { vexInstance });
          ctx.setAccessibleCloseButton(vexInstance.rootEl, vexInstance);
          a11yUtils.focusTrapOn(vexInstance.rootEl);
        },
        afterClose() {
          const vexInstance = this;
          ctx.EMIT(ctx.CUSTOM_MESSAGES.MODAL_EVENTS.afterClose, { vexInstance });
        },
        beforeClose() {
          const vexInstance = this;
          ctx.EMIT(ctx.CUSTOM_MESSAGES.MODAL_EVENTS.beforeClose, { vexInstance });
          document.body.classList.remove('vex-open');
          a11yUtils.focusTrapOff();
        }
      },
      ...opts,
      className: `vex-theme-default ${opts.className ? opts.className : ''}`
    });
  }

  setAccessibleCloseButton(modalEl, instance) {
    let dialog = modalEl.querySelector('[role="dialog"]');
    if (!dialog) {
      return;
    }
    let pseudoClose = document.createElement('button');
    pseudoClose.classList.add('vex-close-pseudo');
    pseudoClose.setAttribute('aria-label', 'close');
    dialog.appendChild(pseudoClose);
    pseudoClose.addEventListener('click', () => {
      instance.close();
    });
  }

  closeModals() {
    return this.Vex.closeAll();
  }
}
