const INPUT_FILLED_CLASS = 'input-filled';

function checkValue(input) {
  if ($(input).val() !== '') {
    $(input).addClass(INPUT_FILLED_CLASS);
  } else {
    $(input).removeClass(INPUT_FILLED_CLASS);
  }
}
function checkAllInputs() {
  $.each($('.animated-label input:not([type="hidden"]) , .animated-label textarea'), (index, value) => {
    checkValue(value);
  });
}
module.exports = {
  init: function() {
    $('body')
      .on('blur change', '.animated-label input:not([type="hidden"]) , .animated-label textarea', (event) => {
        checkValue(event.currentTarget);
      })
      .on('focus', '.animated-label input:not([type="hidden"]) , .animated-label textarea', (event) => {
        $(event.currentTarget).removeClass(INPUT_FILLED_CLASS);
      });
  },
  checkAllInputs: checkAllInputs
};
