console.info('app_project app.js is loaded');

// uncomment this only if main.js is removed and you want to retrieve SFRA client scripts
window.jQuery = window.$ = require('jquery');

window.eventQueue = new Map();
import 'array-flat-polyfill';
import 'what-input';
import 'intersection-observer';
import './utilities/PublicPath';
import FormValidator from './utilities/FormValidator';
import './form/formCountrySelector';
import ModalService from './services/ModalService';
import LoaderService from './services/LoaderService';
import DataLayerService from './services/DataLayerService';
import scrollUtils from './utilities/scrollUtils';
import { ResolutionHandler } from './utilities/ResolutionHandler';
import lazySizes from 'lazysizes';
import vhCheck from 'vh-check';
import { ComponentDispatcher } from 'html-scoped-component-importer';
import { delegate } from 'tippy.js';
import fillWishlistHeart from './utilities/fillWishlistHeart';
import cookie from './cookie';
import trackingUtils from './utilities/trackingUtils';

cookie();

window.onload = function(){
  trackingUtils.startTracking();
}

vhCheck({
  bind: true,
  redefineVh: true,
  updateOnTouch: true
});

const compDispatcher = new ComponentDispatcher();
compDispatcher.createAsyncComponents().then(() => {
  compDispatcher.observeDomChanges();
});

lazySizes.cfg.loadHidden = false;
lazySizes.cfg.expand = 300;

new ModalService();
new LoaderService();
new DataLayerService();
ResolutionHandler();

scrollUtils.anchorHandler('.header');
window.addEventListener('scroll', (e) => {
  scrollUtils.directionHandler();
});

const formValidator = new FormValidator();
formValidator.start();

import thronDispatcherInit from './thronDispatcher';
thronDispatcherInit();

delegate('body', {
  target: '[data-tooltip]',
  content: (el) => el.dataset.tooltip,
  theme: 'light-border',
  zIndex: 2147493647
});

fillWishlistHeart();
