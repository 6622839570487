'use strict';

const scrollUtils = {
  /**
   * scrollHandler: per verificare se ho scrollato oltre l'header (o una certa altezza),
   * e se sto scrollando UP o DOWN
   * gestendo classi sul body di conseguenza
   * @param: h
   *
   * Per attivarla:


   * */
  scrollPos: 0,
  directionHandler: function() {
    /**
     * add class scrolling-down / up to body
     * ->
     *  window.addEventListener('scroll',(e)=>{
     *    scrollUtils.directionHandler();
     *  });
     * */
    if (document.body.getBoundingClientRect().top > this.scrollPos) {
      document.documentElement.classList.add('scrolling-up');
      document.documentElement.classList.remove('scrolling-down');
    } else {
      document.documentElement.classList.add('scrolling-down');
      document.documentElement.classList.remove('scrolling-up');
    }
    // saves the new position for iteration.
    this.scrollPos = document.body.getBoundingClientRect().top;
  },

  anchorHandler(offsetSelector = null) {
    /** TO DO (nice-to-have)
     * set callback  + merge it with scrollToComponent
     * */
    let anchorLinks = document.querySelectorAll('a[href^=\\#]');
    let offset = 0;
    if (offsetSelector) {
      let offsetElement = document.querySelector(offsetSelector);
      offset = offsetElement != null ? offsetElement.offsetHeight : 0;
    }
    anchorLinks.forEach((link) => {
      link.addEventListener(
        'click',
        (e) => {
          if (e.currentTarget.hash !== '' && !e.currentTarget.dataset.toggle) {
            let target = document.querySelector(e.currentTarget.hash);
            let targetPosition = target.getBoundingClientRect().top;
            targetPosition -= offset;
            if (targetPosition > 0) {
              targetPosition += window.scrollY;
            }
            /**
             * uso un timeout per forzare lo scrollto
             * perché non ho trovato chi lancia uno scrollto prima di questa funzione
             */
            window.setTimeout(function() {
              window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
              });
            }, 100);
          }
        },
        {
          passive: true
        }
      );
    });
  }
};
export default scrollUtils;
