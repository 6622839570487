import deepFreeze from '../utilities/Freeze';

export default deepFreeze({
  ADDTOCART_EVENTS: {
    sizeSelected: 'size:selected',
    colorSelected: 'color:selected',
    itemAdded: 'item:added',
    cartRetrieved: 'cart:retrieved'
  },
  HEADER_EVENTS: {
    flyoutShow: 'flyout:show',
    flyoutHide: 'flyout:hide',
    isOpen: 'header:open',
    isClosed: 'header:closed',
    closeSearchDesktop: 'header:closeSearch'
  },
  LOADER_EVENTS: {
    show: 'loader:show',
    hide: 'loader:hide'
  },
  CAROUSEL_EVENTS: {
    swipe: 'carousel:swipe',
    CHANGE: 'carousel:update',
    SET_VISIBLES: 'carousel:setVisibles'
  },
  CHECKOUT: {
    UPDATE_BILLING: 'checkout.update.billing',
    UPDATE_SUMMARY: 'checkout.update.summary',
    UPDATE_STAGE: 'checkout.update.stage',
    UPDATE_PAYMENTS: 'checkout.update.payments',
    SUBMIT_PAYMENT: 'checkout.submit.payment',
    UPDATE_US_LABEL: 'checkout.update.us.label',
    UPDATE_SHIPPING_ADDRESS: 'checkout.update.shippingAddress',
    UPDATE_SHIPPING_ADDRESSES_LIST: 'checkout.update.shippingAddressesList',
    UPDATE_BILLING_ADDRESS: 'checkout.update.billingAddress',
    CHECKED_BILLING_ADDRESS: 'checkout.checked.billingAddress'
  },
  DATALAYER_EVENTS: {
    PUSH: 'dataLayer.push',
    UPDATE_OPTION: 'dataLayer.update.option',
    CHECK_NEW_IMPR: 'dataLayer.check.impression',
    CHECK_NEW_IMPR_GA4: 'dataLayer.check.impression.ga4',
    CHECK_NEW_CLICK_GA4: 'dataLayer.check.click.ga4'
  },
  ADDRESS: {
    CANCEL_ACTION: 'address.cancel.action',
    REMOVED: 'address:removed',
    EDIT: 'address:edit',
    SELECT_PREFERRED: 'addressPreferred:select'
  },
  FILTER_EVENTS: {
    filterApplied: 'filter:applied',
    removeFilter: 'filter:remove',
    overlayIsActive: 'filter:overlay'
  },
  DROPDOWN_EVENTS: {
    close: 'dropdown:close'
  },
  MODAL_EVENTS: {
    show: 'modal:show',
    close: 'modal:close',
    afterOpen: 'modal:afterOpen',
    afterClose: 'modal:afterClose',
    beforeClose: 'modal:beforeClose',
    serviceReady: 'modal:service:ready',
    appendContent: 'modal:appendContent'
  },
  SEARCH_EVENTS: {
    isOpen: 'search:open',
    doSearch: 'search:doSearch',
    loadMore: 'search:loadMore'
  },
  SORTING_RULES: {
    sortingApplied: 'sorting:applied'
  },
  CART: {
    UPDATE_QUANTITY: 'quantity:update',
    DELETED_ITEM: 'item-cart:delete',
    ADD_COUPON: 'coupon:add'
  },
  WISHLIST: {
    ADDED: 'wishlist:added',
    REMOVED: 'wishlist:removed'
  },
  RETURN: {
    ITEM_UPDATE: 'return:update'
  },
  ACCORDION_EVENTS: {
    CLOSE_ALL: 'accordion:closeAll',
    ACTIVE_FILTER: 'accordion:activeFilter',
    INIT: 'accordion:init'
  },
  NEWSLETTER: {
    UPDATE_POPUP: 'newsletter:updatePopup',
    DISABLED: 'newsletter:disabled',
    ENABLED: 'newsletter:enabled'
  },
  FIND_IN_STORE: {
    REMOVE_SELECTED_SIZE: 'findInStore:removeSelectedSize',
    SET_DATA_RESULT: 'findInStore:setDataResult'
  },
  GIVEX: {
    SHOW_BALANCE: 'givex:showBalance'
  }
});
