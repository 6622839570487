/* eslint-disable no-control-regex */
import Bouncer from 'formbouncerjs';

const BASE_CONF = {
  // Classes & IDs
  fieldClass: 'error', // Applied to fields with errors
  errorClass: 'error-message', // Applied to the error message for invalid fields
  fieldPrefix: 'bouncer-field_', // If a field doesn't have a name or ID, one is generated with this prefix
  errorPrefix: 'bouncer-error_', // Prefix used for error message IDs

  customValidations: {
    valueMismatch: function(field) {
      // Look for a selector for a field to compare
      // If there isn't one, return false (no error)
      var selector = field.getAttribute('data-bouncer-match');
      if (!selector) {
        return false;
      }

      // Get the field to compare
      var otherField = field.form.querySelector(selector);
      if (!otherField) {
        return false;
      }

      // Compare the two field values
      // We use a negative comparison here because if they do match, the field validates
      // We want to return true for failures, which can be confusing
      return otherField.value !== field.value;
    }
  },

  // Patterns
  // Validation patterns for specific input types
  patterns: {
    // eslint-disable-next-line no-control-regex
    // eslint-disable-next-line max-len
    email: /^([^\x00-\x20"(),.\x3a-\x3c>@\x5b-\x5d\x7f-\xff]+|"([^\x0d"\\\x80-\xff]|\\[\x00-\x7f])*")(\.([^\x00-\x20"(),.\x3a-\x3c>@\x5b-\x5d\x7f-\xff]+|"([^\x0d"\\\x80-\xff]|\\[\x00-\x7f])*"))*@([^\x00-\x20"(),.\x3a-\x3c>@\x5b-\x5d\x7f-\xff]+|\[([^\x0d\x5b-\x5d\x80-\xff]|\\[\x00-\x7f])*])(\.([^\x00-\x20"(),.\x3a-\x3c>@\x5b-\x5d\x7f-\xff]+|\[([^\x0d\x5b-\x5d\x80-\xff]|\\[\x00-\x7f])*]))*(\.\w{2,})+$/
  },

  // Message Settings
  messageAfterField: true, // If true, displays error message below field. If false, displays it above.
  messageCustom: 'data-bouncer-message', // The data attribute to use for customer error messages
  messageTarget: 'data-bouncer-target', // The data attribute to pass in a custom selector for the field error location

  // Error messages by error type
  messages: {
    ...window.formsErrorsMessages,
    valueMismatch: function(field) {
      var customMessage = field.getAttribute('data-bouncer-mismatch-message');
      return customMessage ? customMessage : 'Please make sure the fields match.';
    }
  },

  // Form Submission
  disableSubmit: true, // If true, native form submission is suppressed even when form validates

  // Custom Events
  emitEvents: true // If true, emits custom events
};

export const validateInput = () => {
  const bouncer = new Bouncer(null, BASE_CONF);
  return bouncer.validate;
};

export const validateAll = (el) => {
  const bouncer = new Bouncer(null, BASE_CONF);
  return bouncer.validateAll(el);
};

export default class FormValidator {
  get BASE_CONF() {
    return BASE_CONF;
  }

  get VALIDATE() {
    const validator = new Bouncer(null, this.BASE_CONF);
    return validator.validate;
  }

  constructor(selector) {
    this.selector = selector;
    this.cleanFieldsError();
  }

  start() {
    this.validator = new Bouncer(this.selector || 'form:not([data-bouncer-novalidate])', this.BASE_CONF);
    this.cleanFieldsError();
  }

  cleanFieldsError() {
    document.addEventListener(
      'bouncerFormInvalid',
      (event) => {
        this.removeCustomError(event);
        event.detail.errors[0].scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      },
      false
    );
    document.addEventListener(
      'bouncerShowError',
      (event) => {
        this.removeCustomError(event);
        if (event.target.closest('fieldset') && event.target.closest('fieldset').classList.contains('d-none')) {
          const form = event.target.closest('form');
          const fieldsets = form.querySelectorAll('fieldset');
          [...fieldsets].forEach((fieldset) => {
            fieldset.classList.remove('d-none');
          });
        }
      },
      false
    );
  }

  removeCustomError(event) {
    const parent = event.target.parentElement;
    const customError = parent.querySelector('.js-error-message');
    if (customError) {
      parent.removeChild(customError);
    }
  }
}
