let currentCountry;
let formName;
let formUrl;
var formContainer = '.form-replace';
var inputPlaceHolder = require('./inputPlaceholder');

function loadForm(parentForm) {
  $.ajax({
    url: formUrl,
    type: 'get',
    dataType: 'html',
    beforeSend: function() {
      $.spinner().start();
    },
    success: function(data) {
      $(formContainer, parentForm).html(data);
      var parentAccordion = $(formContainer).parents('.accordion');
      if (parentAccordion.length) {
        parentAccordion[0].handorgel.resize();
      }
      inputPlaceHolder.checkAllInputs();
      $.spinner().stop();
    },
    error: function(error) {
      console.error(error);
      $.spinner().stop();
    }
  });
}

function formSelectCountry() {
  currentCountry = $('select[name="country-selector"]').val();
  $(document).on('change', 'select[name="country-selector"]', function(event) {
    const parentForm = $(this).parents('form');
    currentCountry = $(event.currentTarget).val();
    formName = $(event.currentTarget).attr('data-form-name');
    formUrl = `${$(event.currentTarget).data('formUrl')}?form=${formName}&locale=${currentCountry}`;
    loadForm(parentForm);
  });
}
export default formSelectCountry;
