import eventbus from 'gia/eventbus';
import config from 'gia/config';

const customEventBus = eventbus; // assign needed cause gia don't offer types
customEventBus.emit = (event, eventObject = {}) => {
  eventObject._name = event;
  if (customEventBus.list[event]) {
    if (config.get('log')) {
      console.info(`${customEventBus.list[event].length} handler${customEventBus.list[event].length > 1 ? 's' : ''} called on event '${event}'`);
    }
    customEventBus.list[event].forEach((handlerObject) => {
      handlerObject.handler(eventObject);
      if (handlerObject.once) {
        customEventBus.off(event, handlerObject.handler);
      }
    });
  } else {
    console.log(`adding ${event} to queue`);
    if (!window.eventQueue.get(event)) {
      window.eventQueue.set(event, []);
    }
    window.eventQueue.get(event).push(eventObject);
    if (config.get('log')) {
      console.info(`0 handlers called on event '${event}'`);
    }
  }
};

export default customEventBus;
