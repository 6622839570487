'use strict';

const a11yUtils = {
  keycode: {
    // http://keycode.info/
    enter: 13,
    space: 32,
    esc: 27,
    arrowUp: 38,
    arrowRight: 39,
    arrowDown: 40,
    arrowLeft: 47
  },
  focusedElementsBeforeModal: [],
  focusableSelectors: 'button:not([disabled]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), [role="button"]',

  focusTrapOn(modal, isPopup = false) {
    this.focusedElementsBeforeModal.push(document.activeElement);
    const focusableElements = this.getAllFocusableElements(modal);
    let firstFocusableElement = !isPopup ? focusableElements[0] : modal;
    const lastFocusableElement = focusableElements[focusableElements.length - 1];
    if (modal.querySelector('[role="dialog"]')) {
      modal.querySelector('[role="dialog"]').focus();
    } else {
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
    modal.addEventListener('keydown', (e) => this.trapTabKey(e, firstFocusableElement, lastFocusableElement));
  },

  keydownHandlerPopup(e, firstFocusableElement, lastFocusableElement) {
    this.trapTabKey(e, firstFocusableElement, lastFocusableElement);
  },

  focusTrapOff() {
    if (this.focusedElementsBeforeModal.length === 0) {
      return;
    }
    let focusedElementNow = this.focusedElementsBeforeModal[this.focusedElementsBeforeModal.length - 1];
    focusedElementNow.focus();
    this.focusedElementsBeforeModal.pop();
  },

  trapTabKey(e, firstEl, lastEl) {
    if (e.keyCode === 9) {
      // TAB
      if (e.shiftKey) {
        // + SHIFT
        if (document.activeElement === firstEl) {
          e.preventDefault();
          lastEl.focus();
        }
      } else {
        if (document.activeElement === lastEl) {
          e.preventDefault();
          firstEl.focus();
        }
      }
    }
  },

  getFirstFocusable(parent) {
    return this.getAllFocusableElements(parent)[0];
  },

  getAllFocusableElements(parent, noFilter = false) {
    return [...parent.querySelectorAll(this.focusableSelectors)].filter((element) => {
      // check if isn't hidden (:visible selector not workink in plain js)
      return element.offsetWidth > 0 || element.offsetHeight > 0;
    });
  },

  toggleEveryTabindex(wrapper, value = -1) {
    if (!wrapper) {
      console.error('Wrapper element is needed in toggleEveryTabindex()');
      return;
    }
    this.getAllFocusableElements(wrapper).forEach((el) => {
      el.setAttribute('tabindex', value);
    });
  }
};
export default a11yUtils;
