window.mqObj = {};
let mqObj = { sm: '768px', md: '1024px', lg: '1440px' };
window.mqObj = mqObj;

const match = (max = null, min = null) => {
  if (!max && !min) {
    return false;
  }

  let maxString = '';
  let minString = '';

  if (max) {
    maxString = `(max-width: ${parseFloat(window.mqObj[max]) - 1}px)${min ? ' and ' : ''}`;
  }

  if (min) {
    minString = `(min-width: ${window.mqObj[min]})`;
  }

  return window.matchMedia(`${maxString}${minString}`).matches;
};

('use strict');

function initPlayer(playerEl) {
  // playerEl = playerEl;
  // playerEl.dataset = playerEl.dataset;

  var actualDisplayed = '';
  if (playerEl.dataset.optionMobileId !== null && playerEl.dataset.optionMobileId !== undefined) {
    sizeCheck(actualDisplayed, null, playerEl);
  } else {
    var id = playerEl.dataset.optionId;
    actualDisplayed = 'desktop';
    bindPlayer(id, actualDisplayed, null, playerEl);
  }
}

function sizeCheck(actualDisplayed, player, playerEl) {
  var id;
  if (match(null, 'sm')) {
    id = playerEl.dataset.optionId;
    actualDisplayed = 'desktop';
  } else {
    actualDisplayed = 'mobile';
    if (playerEl.dataset.optionMobileId !== 'null') {
      id = playerEl.dataset.optionMobileId;
    } else {
      id = playerEl.dataset.optionId;
    }
  }
  bindPlayer(id, actualDisplayed, player, playerEl);
}

function bindPlayer(id, actualDisplayed, player, playerEl) {
  var randomNumber = Math.floor(Math.random() * 10000000000000000);

  playerEl.id = 'thron_' + id + '_' + randomNumber;
  playerEl.parentElement.style.position = 'relative';
  if (playerEl.dataset.optionBackground) {
    playerEl.classList.add('bg-mode');
  } else {
    playerEl.style.height = 'inherit';
  }

  var playerTypeString = playerEl.dataset.optionPlayerType;
  var playerTypeCode = getPlayerTypeCode(playerTypeString);
  //console.log('playerTypeString:', playerTypeString, ' playerTypeCode:', playerTypeCode);
  var is360 = playerEl.dataset.is360 == 'true';

  var options = {
    clientId: 'woolrich',
    xcontentId: id,
    sessId: playerTypeCode,
    // embedCodeId: '8e3e1cc72fd64446862b0226f66560ef',
    noSkin: false,
    mouseWheelZoom: false,
    // autoplay: playerEl.dataset.optionAutoplay || false,
    loop: playerEl.dataset.optionLoop || true,
    muted: playerEl.dataset.optionMuted || false,
    disableUserProfiling: true,
    ignoreUserBehavior: true
  };

  var options360 = {
    clientId: 'woolrich',
    xcontentId: id,
    sessId: playerTypeCode,
    noSkin: false,
    disableUserProfiling: true,
    ignoreUserBehavior: true
  };

  if (player) {
    player.destroy();
  }

  if (elementInViewport(playerEl)) {
    var dateStart = new Date();
    var hours = dateStart.getHours();
    var minutes = dateStart.getMinutes();
    var seconds = dateStart.getSeconds();
    var milliseconds = dateStart.getMilliseconds();
    var completeTimeStart = hours + 'h :' + minutes + 'm :' + seconds + 's :' + milliseconds + 'ms';

    player = THRONContentExperience('thron_' + id + '_' + randomNumber, is360 ? options360 : options);
    player.on('ready', function(playerInstance) {
      var containerPlayer = playerInstance.container();
      if (playerInstance.content().type === 'VIDEO') {
        var isSlideActive = containerPlayer.closest('.glide__slide--active');
        if (isSlideActive) {
          player.volume(0);
          playerInstance.play();
        }
        if (containerPlayer.closest('.glide') && containerPlayer.closest('.glide').querySelectorAll('.glide__slide').length > 1) {
          observeChange(playerInstance, containerPlayer, playerEl);
        } else if (containerPlayer.closest('.glide')) {
          // forceAutoplay(playerInstance, containerPlayer, playerEl);
          player.volume(0);
          playerInstance.play();
        }
      } else {
        var containerPlayer = playerInstance.container();
        if (!is360) {
          containerPlayer.style.zIndex = -1;
        }
        clearImgAltAttribute(playerEl);
      }
      var dateEnd = new Date();
      var hours = dateEnd.getHours();
      var minutes = dateEnd.getMinutes();
      var seconds = dateEnd.getSeconds();
      var milliseconds = dateEnd.getMilliseconds();
      var completeTimeEnd = hours + 'h :' + minutes + 'm :' + seconds + 's :' + milliseconds + 'ms';

      var timeDiff = dateStart.getTime() - dateEnd.getTime();

      window.thronInitTracker.push({ element: playerEl, timeBegin: completeTimeStart, timeEnd: completeTimeEnd, executionTime: timeDiff + 'ms' });
    });
  } else {
    var dateStart = new Date();
    var hours = dateStart.getHours();
    var minutes = dateStart.getMinutes();
    var seconds = dateStart.getSeconds();
    var milliseconds = dateStart.getMilliseconds();
    var completeTimeStart = hours + 'h :' + minutes + 'm :' + seconds + 's :' + milliseconds + 'ms';

    window.addEventListener('load', function afterLoadPage() {
      player = THRONContentExperience('thron_' + id + '_' + randomNumber, options);
      player.on('ready', function(playerInstance) {
        var containerPlayer = playerInstance.container();
        if (playerInstance.content().type === 'VIDEO') {
          player.volume(0);
          let domElement = document.getElementById(player.id);
          let videoTag = domElement.getElementsByTagName('video');
          videoTag[0].setAttribute('muted', 'true');
          if (containerPlayer.closest('.glide') && containerPlayer.closest('.glide').querySelectorAll('.glide__slide').length > 1) {
            observeChange(playerInstance, containerPlayer, playerEl);
          }
        } else {
          var containerPlayer = playerInstance.container();
          if (!is360) {
            containerPlayer.style.zIndex = -1;
          }
          clearImgAltAttribute(playerEl);
        }
        var dateEnd = new Date();
        var hours = dateEnd.getHours();
        var minutes = dateEnd.getMinutes();
        var seconds = dateEnd.getSeconds();
        var milliseconds = dateEnd.getMilliseconds();
        var completeTimeEnd = hours + 'h :' + minutes + 'm :' + seconds + 's :' + milliseconds + 'ms';

        var timeDiff = dateEnd.getTime() - dateStart.getTime();

        window.thronInitTracker.push({ element: playerEl, timeBegin: completeTimeStart, timeEnd: completeTimeEnd, executionTime: timeDiff + 'ms' });
        window.removeEventListener('load', afterLoadPage, true);
      });
    });
  }
}

function forceAutoplay(playerInstance, containerPlayer, playerEl) {
  if (containerPlayer.dataset.optionAutoplay === 'true') {
    // console.log('containerPlayer not glide is autoplay ---------->', containerPlayer);
    var isElementInViewport = elementInViewport(containerPlayer);
    if (!isElementInViewport) {
      playerInstance.stop();
      playerInstance.volume(0);
    }
  }
}

function observeChange(playerInstance, containerPlayer, playerEl) {
  var parentSlide = containerPlayer.closest('.glide__slide');
  const mutationObserver = new MutationObserver(function callback(mutationsList, observer) {
    mutationsList.forEach((mutation) => {
      if (mutation.attributeName === 'class' && mutation.target) {
        if (mutation.target.classList.contains('glide__slide--active')) {
          // console.log('el to play ------>', playerInstance);
          playerInstance.play();
        } else {
          playerInstance.pause();
        }
      }
    });
  });
  mutationObserver.observe(parentSlide, { attributes: true, childList: false, subtree: false });
}

function elementInViewport(el) {
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;
  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }
  return top < window.pageYOffset + window.innerHeight && left < window.pageXOffset + window.innerWidth && top + height > window.pageYOffset && left + width > window.pageXOffset;
}

function getPlayerTypeCode(playerTypeString) {
  var playerTypeContent = {
    Template_base: 'fdynkn',
    Video_autoplay_toolbar_on_audio_on: 'ragbrs',
    Video_no_audio: 'vcljnm',
    Video_con_sottotitoli: '8dvqvz',
    video_noautoplay_toolbar: 'kecyqc',
    Video_3: 'i54oqm'
  };
  return playerTypeContent[playerTypeString] || playerTypeContent.Template_base;
}

function clearImgAltAttribute(playerEl) {
  var playerImage = playerEl.querySelector('.th-image');
  if (playerImage) {
    playerImage.setAttribute('alt', '');
  }
}

module.exports = function() {
  var allThronPlayer = document.querySelectorAll('[data-component="ThronComponent"]');
  window.thronInitTracker = [];

  if (allThronPlayer.length > 0) {
    allThronPlayer.forEach(function(playerEl) {
      if (playerEl) {
        initPlayer(playerEl);
      }
    });
  }
};
