import uuid from 'uuid/v1';
import BaseService from '../base/BaseService';

export default class LoaderService extends BaseService {
  get Messages() {
    return {
      [this.CUSTOM_MESSAGES.LOADER_EVENTS.show]: this.appendLoader,
      [this.CUSTOM_MESSAGES.LOADER_EVENTS.hide]: this.removeLoader
    };
  }

  static get SELECTORS() {
    return {
      loader: '.js-loader',
      message: '.js-message'
    };
  }

  constructor() {
    super();

    this.activeLoader = {};
    this.timeoutFunction = {};
  }

  /**
   *
   * @param {String} [message]
   */
  static loaderElement(message) {
    const loaderEl = document.createElement('div');
    const dots = '<span class="dot"></span><span class="dot"></span><span class="dot"></span>';
    loaderEl.classList.add('loader', 'js-loader');
    loaderEl.innerHTML = dots;
    if (message) {
      LoaderService.appendMessage(message, loaderEl);
    }

    return loaderEl;
  }

  /**
   *
   * @param {String} message
   * @param {String} [icon=icon-check]
   * @param {Boolean} [error=false]
   *
   * @returns {Element}
   */
  static messageElement(message, icon = 'icon-check', error = false) {
    const messageEl = document.createElement('div');
    messageEl.classList.add('message', 'js-message');
    if (icon) {
      messageEl.classList.add(error ? 'icon-alert' : icon);
    } else {
      messageEl.classList.add(error ? 'error' : 'success');
    }
    messageEl.innerHTML = message;

    return messageEl;
  }

  /**
   *
   * @param {HTMLElement} [container]
   */
  appendLoader({ container, message }) {
    try {
      const activeLoader = container.querySelector(LoaderService.SELECTORS.loader);
      const dataUuid = container.getAttribute('data-loader');

      if (!dataUuid) {
        const elUuid = uuid();
        container.setAttribute('data-loader', elUuid);
        this.activeLoader[elUuid] = [container];
      } else {
        this.activeLoader[dataUuid].push(container);
      }

      if (!activeLoader) {
        if (container.nodeName === 'button') {
          container.style.overflow = 'hidden';
          container.setAttribute('disabled', 'disabled');
        }
        container.insertBefore(LoaderService.loaderElement(message), container.firstChild);
      }
    } catch (e) {
      console.error(e);
    }
  }

  static appendMessage(message, container, icon, error) {
    const messageEl = LoaderService.messageElement(message, icon, error);
    container.appendChild(messageEl);
    messageEl.classList.add('visible');
    return messageEl;
  }

  /**
   *
   * @param {HTMLElement} [container]
   * @param {String} [message]
   * @param {String} [icon]
   * @param {Boolean} [error]
   */
  removeLoader({ container, message, icon, error, time = 5000 }) {
    try {
      const dataUuid = container.getAttribute('data-loader');
      const activeLoader = container.querySelector(LoaderService.SELECTORS.loader);
      if (message) {
        const messageEl = LoaderService.appendMessage(message, container, icon, error);
        messageEl.parentElement.classList.add('custom-message');
        setTimeout(() => {
          if(messageEl) {
            messageEl.classList.remove('visible');
          }
        }, time);
        setTimeout(() => {
          if(messageEl && messageEl.parentElement) {
            messageEl.parentElement.classList.remove('custom-message');
            messageEl.parentElement.removeChild(messageEl);
          }
        }, time + 500);
      }

      if (this.activeLoader[dataUuid] && this.activeLoader[dataUuid].length) {
        this.activeLoader[dataUuid].pop();
        if (this.activeLoader[dataUuid].length === 0) {
          delete this.activeLoader[dataUuid];
        }
      }

      if (activeLoader && !this.activeLoader[dataUuid]) {
        if (container.nodeName === 'button') {
          container.removeAttribute('disabled');
          container.style.overflow = '';
        }
        container.removeAttribute('data-loader');
        activeLoader.parentElement.removeChild(activeLoader);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
