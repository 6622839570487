'use strict';

window.mqObj = {};
let mqObj = {smCarousel: '641px', sm: '768px', md: '1024px', lg: '1440px' };

export const ResolutionHandler = () => {
  window.mqObj = mqObj;
};

/**
 *
 * @param {string} max - The name of the max-width breackpoint
 * @param {string} min - The name of the min-width breackpoint
 * @return {boolean}
 */
export const match = (max = null, min = null) => {
  if (!max && !min) {
    return false;
  }

  let maxString = '';
  let minString = '';

  if (max) {
    maxString = `(max-width: ${parseFloat(window.mqObj[max]) - 1}px)${min ? ' and ' : ''}`;
  }

  if (min) {
    minString = `(min-width: ${window.mqObj[min]})`;
  }

  return window.matchMedia(`${maxString}${minString}`).matches;
};

export const isTablet = () => {
  let tablet = false;
  try {
    const userAgent = window.navigator.userAgent.toLowerCase();
    tablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  } catch (error) {
    console.error(error);
  }
  return tablet;
};
